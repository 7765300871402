<template>
  <div class="container-fluid px-0 mx-0">
    <top-menu-component
      :isLoggedIn="api.isLoggedIn"
      style="position: fixed; top: 0; z-index:1; width: 100%"
    />
    <div
      class="d-flex flex-row justify-content-between background-container"
      style="margin-top: 150px !important"
    >
      <sidebar-component v-if="api.isLoggedIn" />
      <div
        style="
          right: 0;
          position: fixed;
          height: 100%;
          overflow: scroll;
          padding-bottom:300px;
        "
        :style="api.isLoggedIn ? 'width:80%' : 'width:100%' "
        class="container-fluid pt-3"
      >
        <router-view />
      </div>
    </div>
    <footer-component class="mt-5" style="position: fixed; bottom: 0; z-index: 999999" />
  </div>
</template>
<script>
// import SidebarLayout from "./Layout/SidebarLayout.vue";
import { useUserStore } from "./store/UserService";

export default {
  data() {
    return {
      api: useUserStore(),
      collapsed: false,
      isOnMobile: false,
    };
  },
  computed: {
    Menu: function () {
      return [
        {
          header: true,
          title:
            useUserStore().getUserName + " ( " + useUserStore().getChamberName + " )",
          hiddenOnCollapse: true,
        },
        {
          href: "/dashboard",
          title: this.$t("dashboard"),
          icon: "fa fa-columns",
        },
        {
          title: this.$t("configuration"),
          icon: "fas fa-tools",
          hidden: useUserStore().getIsAdmin != 1,
          child: [
            {
              title: this.$t("localities"),
              icon: "fas fa-map-marked",
              child: [
                {
                  href: "/localities",
                  title: this.$t("localities"),
                  icon: "fas fa-city",
                },
                {
                  href: "/nations",
                  title: this.$t("countries"),
                  icon: "fas fa-globe",
                },
              ],
            },

            {
              title: this.$t("jurisdictions"),
              icon: "fas fa-gavel",
              child: [
                {
                  href: "/jurisdiction_types",
                  title: this.$t("jurisdiction_types"),
                  icon: "fas fa-wrench",
                },
                {
                  href: "/jurisdictions",
                  title: this.$t("jurisdictions"),
                  icon: "fas fa-gavel",
                },
                {
                  href: "/chambers",
                  title: this.$t("chambers"),
                  icon: "fas fa-users",
                },
              ],
            },

            {
              title: this.$t("users"),
              icon: "fas fa-users",
              child: [
                {
                  href: "/positions",
                  title: this.$t("positions"),
                  icon: "fas fa-user-tie",
                },
                {
                  href: "/users",
                  title: this.$t("users"),
                  icon: "fas fa-user",
                },
                {
                  href: "/admins",
                  title: this.$t("administrators"),
                  icon: "fas fa-cog",
                },
              ],
            },

            {
              title: this.$t("investigations"),
              icon: "fas fa-file",
              child: [
                {
                  href: "/application_status",
                  title: this.$t("application_status"),
                  icon: "fas fa-cog",
                },
                {
                  href: "/person_types",
                  title: this.$t("person_types"),
                  icon: "fas fa-cog",
                },

                {
                  href: "/affairs",
                  title: this.$t("affairs"),
                  icon: "fas fa-cog",
                },
                {
                  href: "/referrals",
                  title: this.$t("referrals"),
                  icon: "fas fa-tasks",
                },
                {
                  href: "/person_status_in_investigations",
                  title: this.$t("person_status_in_investigations"),
                  icon: "fas fa-cog",
                },
                {
                  href: "/person_situation_in_investigations",
                  title: this.$t("person_situation_in_investigations"),
                  icon: "fas fa-cog",
                },
                {
                  href: "/investigation_events",
                  title: this.$t("investigation_events"),
                  icon: "fas fa-cog",
                },
              ],
            },

            {
              title: this.$t("audiences"),
              icon: "fas fa-calendar",
              child: [
                {
                  href: "/audience_types",
                  title: this.$t("audience_types"),
                  icon: "fas fa-cog",
                },

                {
                  href: "/audience_results",
                  title: this.$t("audience_results"),
                  icon: "fas fa-cog",
                },
              ],
            },

            {
              href: "/trames",
              title: this.$t("trames"),
              icon: "fas fa-cog",
            },

            {
              href: "/attachment_types",
              title: this.$t("attachment_types"),
              icon: "fas fa-cog",
            },
            {
              href: "/repport_types",
              title: this.$t("repport_types"),
              icon: "fas fa-cog",
            },
            {
              href: "/settings",
              title: this.$t("settings"),
              icon: "fas fa-cog",
            },
          ],
        },
        {
          href: "/investigation_processes",
          title: this.$t("investigation_processes"),
          icon: "fas fa-sync",
        },
        {
          href: "/audiences",
          title: this.$t("audiences"),
          icon: "fas fa-calendar",
        },
        {
          href: "/persons",
          title: this.$t("persons"),
          icon: "fas fa-users",
        },
        {
          href: "/speakers",
          title: this.$t("speakers"),
          icon: "fas fa-users",
        },
        {
          href: "/public/audiences",
          title: this.$t("audience_roles"),
          icon: "fas fa-calendar",
        },
        {
          href: "/users/" + this.api.getUserId + "/reset_password",
          title: this.$t("reset_password"),
          icon: "fas fa-key",
        },
        {
          title: this.$t("logout"),
          icon: "fas fa-sign-out-alt", // Choisissez une icône appropriée
        },
      ];
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  // components: { SidebarLayout },
  methods: {
    logout() {
      localStorage.clear();
      this.api.setLoggedIn(false);
      this.$router.push("/login");
    },
    onToggleCollapse(collapsed) {
      console.log(collapsed);
      this.collapsed = collapsed;
    },
    onItemClick(event, item, node) {
      console.log(event);
      console.log(item);
      console.log(node);
      if (item.title === this.$t("logout")) {
        this.logout();
      }
    },
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
};
</script>

<style>
.vs-table--header {
  background: #c0c0c040 !important;
  padding-right: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.bg-light-pci {
  background: #c0c0c040 !important;
}
.bg-pci {
  background-color: rgb(56 189 248);
}
.font-pci {
  color: rgb(56 189 248);
}

.pci_theme .vdatetime-popup__header,
.pci_theme .vdatetime-calendar__month__day--selected > span > span,
.pci_theme .vdatetime-calendar__month__day--selected:hover > span > span {
  background: rgb(56 189 248);
}

.pci_theme .vdatetime-year-picker__item--selected,
.pci_theme .vdatetime-time-picker__item--selected,
.pci_theme .vdatetime-popup__actions__button {
  color: rgb(56 189 248);
}

.background-container {
  position: relative;
  overflow: scroll;
  height: 100%; /* Ajustez la hauteur selon vos besoins */
}

/* Créez un pseudo-élément pour afficher l'image en arrière-plan */
.background-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("assets/background.jpg"); /* Spécifiez le chemin de votre image */
  background-size: contain; /* Ajustez la taille de l'image pour remplir l'arrière-plan */
  background-position: center; /* Centrez l'image */
  opacity: 0.1; /* Transparence de 50% */
  z-index: -1;
}

.bg-white {
  background: #fff;
}
.vs-tabs--content {
  position: relative;
  width: 100%;
  display: block;
  padding: 0px !important;
}
.con-vs-popup .vs-popup {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 100;
  width: 900px !important;
  margin: 10px;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-animation: rebound 0.3s;
  animation: rebound 0.3s;
}

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600");

body,
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  background-color: #fff;
  color: #262626;
}

/* #app {
  padding-left: 350px;
  transition: 0.3s ease;
}

#app.loggedIn {
  padding-left: 350px;
  transition: 0.3s ease;
}
#app.loggedOut {
  padding-left: 0px;
  transition: 0.3s ease;
}
#app.collapsed {
  padding-left: 50px;
}
#app.onmobile {
  padding-left: 50px;
} */

.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}

/* .demo {
  padding: 50px;
} */

/* .container {
  max-width: 900px;
} */

pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.v-sidebar-menu .vsm--icon {
  background-color: rgb(56 189 248) !important;
  padding: 3px;
}
.blue-light-text-color {
  color: rgb(56 189 248) !important;
}
.blue-light-bgt-color {
  background-color: rgb(56 189 248) !important;
}
.border-primary {
  border-color: rgb(56 189 248) !important;
}
/* .v-sidebar-menu .vsm--link.vsm--link_exact-active {
  background: orange;
} */
.el-button--text {
  color: rgb(56 189 248)!important;
}
.el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: deepskyblue!important;
  color: white !important;
}
/* Container styling */
.container {
  /* max-width: 1200px; */
  margin: 0 auto;
}

/* Header styling */
.header {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 20px;
}

/* Icon button styling */
.icon-button .el-icon-circle-plus-outline {
  color: #409eff;
}

/* Action button styling */
.action-button {
  text-decoration: none;
  color: #409eff;
}
.el-button--primary {
  color: #FFFFFF;
  background-color: #409eff!important;
  border-color: #409eff!important;
}
.el-dialog__wrapper {
  margin-top: 150px!important;
}
</style>

<template>
  <div>
    <div class="d-flex flex-row justify-content-between bg-white">
      <div class="d-flex flex-row p-2">
        <img src="../../assets/_logo.png" style="max-height: 80px" />
        <div class="pl-2 py-2">
          <div class="pl-2 mb-1">
            <img src="../../assets/flag.png" style="max-height: 25px" />
            <strong class="mx-3 py-1">République du Mali</strong>
          </div>
          <strong class="mt-5 h4 mx-2">{{ $t("project_title") }}</strong>
        </div>
      </div>
      <div>
        <img
          src="../../assets/logo.png"
          style="max-height: 105px; border-bottom: 3px solid darkblue"
        />
      </div>
    </div>

    <div
      v-if="isLoggedIn"
      class="d-flex flex-row justify-content-between"
      style="background: rgb(249, 236, 186); border-top: 3px solid rgb(234, 209, 111)"
    >
      <strong class="h6 py-2 px-2"> {{ $t("welcome_text") }}</strong>

      <div class="mx-2 py-2">
        <span class="el-dropdown-link">
          <i
            class="el-icon-office-building blue-light-text-color mx-2"
            style="font-size: 20px"
          ></i>
          <strong class="mx-2 h6">{{ userApi.getJurisdictionName }}</strong>
        </span>

        <span class="el-dropdown-link">
          <i
            class="el-icon-suitcase blue-light-text-color mx-2"
            style="font-size: 20px"
          ></i>
          <strong class="mx-2 h6">{{ userApi.getChamberName }}</strong>
        </span>

        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <i
              class="el-icon-user-solid blue-light-text-color mx-2"
              style="font-size: 20px"
            ></i>
            <strong class="mx-2 h6">{{ userApi.getUserName }}</strong>
            <i
              class="el-icon-arrow-down el-icon--right blue-light-text-color"
              style="font-size: 20px"
            ></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-s-platform" command="reset_password">{{
              $t("my_account")
            }}</el-dropdown-item>
            <el-dropdown-item icon="el-icon-unlock" command="logout">{{
              $t("logout")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-menu
      :router="true"
      class="d-flex flex-row justify-content-end"
      mode="horizontal"
      background-color="rgb(249, 236, 186)"
      text-color="#000"
      style="border-top: 3px solid rgb(234, 209, 111)"
      active-text-color="rgb(56 189 248)"
      v-else
    >
      <el-menu-item index="1" class="d-flex flex-row" :route="{ name: 'home' }">
        <vs-icon
          class="material-icons-outlined blue-light-text-color"
          icon="home"
          size="40px"
        ></vs-icon>
        <span class="h5 ml-2 py-2">{{ $t("home") }}</span>
      </el-menu-item>

      <el-menu-item
        index="2"
        class="d-flex flex-row"
        :route="{ name: 'public-audiences' }"
      >
        <vs-icon
          class="material-icons-outlined blue-light-text-color"
          icon="article"
          size="40px"
        ></vs-icon>
        <span class="h5 ml-2 py-2">{{ $t("audience_roles") }}</span>
      </el-menu-item>

      <el-menu-item index="3" class="d-flex flex-row" :route="{ name: 'login' }">
        <vs-icon
          class="material-icons-outlined blue-light-text-color"
          icon="login"
          size="40px"
        ></vs-icon>
        <span class="h5 ml-2 py-2">{{ $t("login") }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
import { useUserStore } from "@/store/UserService";
export default {
  data() {
    return {
      userApi: useUserStore(),
    };
  },
  props: ["isLoggedIn"],
  methods: {
    logout() {
      localStorage.clear();
      this.userApi.setLoggedIn(false);
      this.userApi.setChain("");
      this.userApi.setChamberId(null);
      this.userApi.setChamberName("");
      this.$router.push("/");
    },
    handleCommand(command) {
      if (command == "logout") {
        this.logout();
      }
      if (command == "reset_password") {
        this.$router.push("/users/" + this.userApi.getUserId + "/reset_password");
      }
    },
  },
};
</script>
<style scoped>
.el-menu :hover {
  background: none !important;
}
</style>

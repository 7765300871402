import { defineStore } from "pinia";
import http from "./http.js";
export const useUserStore = defineStore("user", {
  state: () => ({
    requestStatus: "",
    error: "",
    users: [],
    user: null,
    chambers:[],
    investigationProcesses:[],
    chamber:localStorage.getItem('chamber') || '',
    chain:localStorage.getItem('chain') || '',
    token: localStorage.getItem('token') || '',
    isAdmin: localStorage.getItem('isAdmin') || '',
    jurisdictionId: localStorage.getItem('jurisdictionId') || '',
    jurisdictionName: localStorage.getItem('jurisdictionName') || '',
    jurisdictionType: localStorage.getItem('jurisdictionType') || '',
    localityAbbreviation: localStorage.getItem('localityAbbreviation') || '',
    positionName: localStorage.getItem('positionName') || '',
    userId: localStorage.getItem('userId') || '',
    userName: localStorage.getItem('userName') || '',
    chamberId: localStorage.getItem('chamberId') || null,
    chamberName: localStorage.getItem('chamberName') || '',
    loggedIn: !!localStorage.getItem('token'),
  }),
  
  getters: {
    getRequestStatus(state) {
      return state.requestStatus;
    },
    getItems(state) {
      return state.users;
    },
    getChambers(state) {
      return state.chambers;
    },
    getItem(state) {
      return state.user;
    },
    getInvestigationProcesses(state){
        return state.investigationProcesses;
    },
    getChamberName(state) {
      return state.chamberName;
    },
    getChamberId(state) {
      return state.chamberId;
    },
    getToken(state) {
      return state.token;
    },
    getIsAdmin(state) {
      return state.isAdmin;
    },
    getJurisdictionId(state) {
      return state.jurisdictionId;
    },
    getPositionName(state) {
      return state.positionName;
    },
    getJurisdictionName(state) {
      return state.jurisdictionName;
    },
    getJurisdictionType(state) {
      return state.jurisdictionType;
    },
    getLocalityAbbreviation(state) {
      return state.localityAbbreviation;
    },
    
    getUserId(state) {
      return state.userId;
    },
    getUserName(state) {
      return state.userName;
    },
    isLoggedIn(state) {
      return state.loggedIn;
    },
    getChain(state) {
      return state.chain;
    },
  },

  actions: {
    login(data) {
      this.requestStatus = "LOADING";
      return http
        .post("authentication/login", data)
        .then((response) => {
          this.user = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },
    index() {
      this.requestStatus = "LOADING";
      return http
        .get("users")
        .then((response) => {
          this.users = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },
    store(data) {
      this.requestStatus = "LOADING";
      return http
        .post("users", data)
        .then((response) => {
          this.user = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },
    show(id) {
        this.requestStatus = "LOADING";
        return http
          .get("users/" + id)
          .then((response) => {
            this.user = response.data;
            this.requestStatus = "SUCCESS";
          })
          .catch((error) => {
            this.error = error;
            this.requestStatus = "FAILED";
          });
      },
      findByEmail(email) {
        this.requestStatus = "LOADING";
        return http
          .get("users/find_by_email/" + email)
          .then((response) => {
            this.user = response.data;
            this.requestStatus = "SUCCESS";
          })
          .catch((error) => {
            this.error = error;
            this.requestStatus = "FAILED";
          });
      },
      update(data) {
        this.requestStatus = "LOADING";
        return http
          .put("users", data)
          .then((response) => {
            this.user = response.data;
            this.requestStatus = "SUCCESS";
          })
          .catch((error) => {
            this.error = error;
            this.requestStatus = "FAILED";
          });
      },
      delete(id) {
        this.requestStatus = "LOADING";
        return http
          .delete("users/" + id)
          .then(() => {
            this.requestStatus = "SUCCESS";
          })
          .catch((error) => {
            this.error = error;
            this.requestStatus = "FAILED";
          });
      },
      fetchChambers(id) {
        this.requestStatus = "LOADING";
        return http
          .get("users/" + id + "/chambers")
          .then((response) => {
            this.chambers = response.data;
            this.requestStatus = "SUCCESS";
          })
          .catch((error) => {
            this.error = error;
            this.requestStatus = "FAILED";
          });
      },
      fetchInvestigationProcesses(id) {
        this.requestStatus = "LOADING";
        return http
          .get("users/" + id + "/investigation_processes")
          .then((response) => {
            this.investigationProcesses = response.data;
            this.requestStatus = "SUCCESS";
          })
          .catch((error) => {
            this.error = error;
            this.requestStatus = "FAILED";
          });
      },
      resetPassword(data){
        this.requestStatus = "LOADING";
        return http
          .put("users/reset_password", data)
          .then((response) => {
            this.user = response.data;
            this.requestStatus = "SUCCESS";
          })
          .catch((error) => {
            this.error = error;
            this.requestStatus = "FAILED";
          });
      },
      setChain(value) {
        this.chain = value;
      },
      
      // After login 
      setChamberName(value) {
        this.chamberName = value;
      },
      setChamberId(value) {
        this.chamberId = value;
      },
      setToken(value) {
        this.token = value;
      },
      setIsAdmin(value) {
        this.isAdmin = value;
      },
      setJurisdictionId(value) {
        this.jurisdictionId = value;
      },
      setPositionName(value) {
        this.positionName = value;
      },
      setJurisdictionName(value) {
        this.jurisdictionName = value;
      },
      setJurisdictionType(value) {
        this.jurisdictionType = value;
      },
      setLocalityAbbreviation(value) {
        this.localityAbbreviation = value;
      },
      setUserId(value) {
        this.userId = value;
      },
      setUserName(value) {
        this.userName = value;
      },
      setLoggedIn(value) {
        this.loggedIn = value;
      },
  },
});

<template>
    <div> 
        <receiver-component
          v-model="formData.userId"
          :trameId="trame.id"
          :jurisdictionId="userApi.getJurisdictionId"
        />

        <div> 
            <label>{{ $t("comment") }}</label>
            <editor-component v-model="formData.comment" />
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            formData:this.form
        }
    },
    props:["form", "trame", "userApi"],
    updated(){
        this.$emit("change", this.formData)
    }
}
</script>
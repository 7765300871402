import axios from "axios";

const http = axios.create({
  //baseURL: "http://127.0.0.1:8080/api/", // Remplacez par l'URL de votre API
  baseURL: "/api",
  // Autres options de configuration d'Axios si nécessaire
});

http.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default http;

<template>
  <div>
    <div v-if="!isEditing">
      <div class="d-flex justify-content-end">
        <el-button icon="el-icon-edit" circle @click="startEditing"></el-button>
      </div>
      <div v-html="content"></div>
    </div>
    <div v-else>
      <vue-editor
        v-model="content"
        :editorToolbar="customToolbar"
        @text-change="$emit('input', this.content)"
      ></vue-editor>
      <!-- <div class="d-flex justify-content-end"> 
            <el-button icon="el-icon-check" circle @click="stopEditing"></el-button>
        </div> -->
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isEditing: this.value == null || !this.value.length,
      content: this.value,
      customToolbar: [
        // Include font style options
        [{ font: [] }],
        // Include header options
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // Include bold, italic, underline, strike options
        ["bold", "italic", "underline", "strike"],
        // Include blockquote option
        ["blockquote"],
        // Include list options
        [{ list: "ordered" }, { list: "bullet" }],
        // Include indent options
        [{ indent: "-1" }, { indent: "+1" }],
        // Include direction option
        [{ direction: "rtl" }],
        // Include size options
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        // Include color and background options
        [{ color: [] }, { background: [] }],
        // Include align options
        [{ align: [] }],
        // Include code block option
        ["code-block"],
        // Include clean option
        ["clean"],
      ],
    };
  },
  updated() {
    this.$emit("input", this.content);
  },
  methods: {
    startEditing() {
      this.isEditing = true;
    },
    stopEditing() {
      this.isEditing = false;
      this.$emit("update:modelValue", this.content);
    },
  },
};
</script>

<style>
.quillWrapper .ql-snow.ql-toolbar {
  padding-top: 8px;
  padding-bottom: 4px;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
</style>

import { defineStore } from "pinia";
import http from "./http.js";

export const usePositionStore = defineStore("position", {
  state: () => ({
    requestStatus: "",
    error: "",
    positions: [],
    position: null,
    accessList: JSON.parse(localStorage.getItem('positionAccessList')) || [], // Liste des accès chargée depuis le localStorage
  }),

  getters: {
    getRequestStatus(state) {
      return state.requestStatus;
    },
    getItems(state) {
      return state.positions;
    },
    getItem(state) {
      return state.position;
    },
    getAccessList(state) {
      return state.accessList;
    }
  },

  actions: {
    index() {
      this.requestStatus = "LOADING";
      return http
        .get("positions")
        .then((response) => {
          this.positions = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },

    store(data) {
      this.requestStatus = "LOADING";
      return http
        .post("positions", data)
        .then((response) => {
          this.position = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },

    show(id) {
      this.requestStatus = "LOADING";
      return http
        .get("positions/" + id)
        .then((response) => {
          this.position = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },

    update(data) {
      this.requestStatus = "LOADING";
      return http
        .put("positions", data)
        .then((response) => {
          this.position = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },

    delete(id) {
      this.requestStatus = "LOADING";
      return http
        .delete("positions/" + id)
        .then(() => {
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.error = error;
          this.requestStatus = "FAILED";
        });
    },

    // Gestion des accès
    async fetchAccessList(positionId) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get(`positions/${positionId}/accesses`);
        this.accessList = response.data;
        this.updateAccessListInLocalStorage(); // Met à jour le localStorage
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.handleHttpError(error);
      }
    },

    async addAccess(positionId, accessData) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.post(`positions/${positionId}/accesses`, accessData);
        // Ajout de l'accès à la liste des accès
        this.accessList.push(response.data);
        this.updateAccessListInLocalStorage(); // Met à jour le localStorage
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.handleHttpError(error);
      }
    },

    async removeAccess(accessId) {
      this.requestStatus = "LOADING";
      try {
        await http.delete(`positions/accesses/${accessId}`);
        // Suppression de l'accès de la liste des accès
        this.accessList = this.accessList.filter(access => access.id !== accessId);
        this.updateAccessListInLocalStorage(); // Met à jour le localStorage
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.handleHttpError(error);
      }
    },

    hasAccess(accessType) {
      // Vérifie si accessType est présent dans la liste des accès
      return this.accessList.some(access => access.accessType === accessType);
    },

    updateAccessListInLocalStorage() {
      localStorage.setItem('positionAccessList', JSON.stringify(this.accessList));
    },

    handleHttpError(error) {
      this.requestStatus = "FAILED";
      if (error.response) {
        // Server responded with a status code
        this.error = error.response.data.message || "An error occurred";
      } else if (error.request) {
        // Request was made but no response was received
        this.error = "No response received from server";
      } else {
        // An error occurred in setting up the request
        this.error = error.message || "An error occurred";
      }
    }
  },
});

<template>
  <div class="d-flex flex-column py-2">
    <label>{{ $t("receiver") }}</label>
    <el-select
      class="w-100"
      v-model="receiverId"
      filterable
      :placeholder="$t('receiver')"
      @change="$emit('input', receiverId)"
    >
      <el-option
        v-for="item in Users"
        :key="item.id"
        :label="item.id == userApi.getUserId ? $t('my_self') : item.name + ' (' + item.positionName + ')'"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { useTrameStore } from "@/store/TrameService";
import { useUserStore } from "@/store/UserService";
import { useJurisdictionStore } from "@/store/JurisdictionService";
export default {
  data() {
    return {
      receiverId: this.value,
      userApi: useUserStore(),
      users: [],
      trameApi: useTrameStore(),
      profiles: [],
      jurisdictionApi: useJurisdictionStore(),
    };
  },
  props: ["trameId", "value", "jurisdictionId"],
  update() {
    this.$emit("input", this.value);
  },
  created() {
    this.getUsers();
    this.getProfiles();
  },
  watch:{
    jurisdictionId: function() { // watch it
      this.getUsers();
    }
  },
  computed: {
    Users: function () {
      return this.users.filter((user) => {
        return (
          user.id != this.userApi.getUserId &&
          this.profiles.some((profile) => profile.toProfileId === user.positionId)
        );
      });
    },
  },
  methods: {
    getUsers() {
      this.$vs.loading();
      this.jurisdictionApi.fetchUsers(this.jurisdictionId).then(() => {
        this.users = this.jurisdictionApi.getUsers;
        this.$vs.loading.close();
      });
    },
    getProfiles() {
      this.trameApi.fetchProfiles(this.trameId).then(() => {
        this.profiles = this.trameApi.getProfiles;
      });
    },
  },
};
</script>
